import * as q from "q";
import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyAJ4bamHebINsH2vwkEt91-2B5njusoW_4",
  authDomain: "pathfinder-125af.firebaseapp.com",
  databaseURL: "https://pathfinder-125af.firebaseio.com",
  projectId: "pathfinder-125af",
  storageBucket: "pathfinder-125af.appspot.com",
  messagingSenderId: "1087741536118",
  appId: "1:1087741536118:web:4970e6832b2db71560fc57",
  measurementId: "G-LVB8GRQ9KS",
};

export function initializeFirebase() {
  try {
    if (firebase.messaging.isSupported()) {
      firebase.initializeApp(firebaseConfig);
      grantPermission();
    }
  } catch (e) {
    console.log(e);
  }
}

export function grantPermission() {
  try {
    const messaging = firebase.messaging();
    messaging
      .requestPermission()
      .then(() => {
        console.log("Notification permission granted");
      })
      .catch((error) => {
        console.log("Unable to get permission to notify. ", error);
      });
  } catch (e) {
    console.log(e);
  }
}

export function getFcmToken() {
  try {
    var defer = q.defer();
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(
      "BC1FOJow1PggjR2_Km2cojSWHcn3xVCNMMr53VvaHmPYo6EfSEUBtWcLLBQcY6109sSvdCFj1ml8jA1mbcWb04Q"
    );
    messaging
      .getToken()
      .then((token) => {
        defer.resolve(token);
      })
      .catch((err) => {
        console.log("An error occured while retrieving token. ", err);
        console.log("Error retrieving Instance ID token. ", err);
        defer.reject(err);
      });
    return defer.promise;
  } catch (e) {
    console.log(e);
  }
}

// const initializeFirebase = () => {
//   firebase.initializeApp(firebaseConfig);

//   const messaging = firebase.messaging();
//   messaging.usePublicVapidKey(
//     "BGK0Lo51cG2pW8-odTUj6_E5xgLgvHj73Uh_NFejivOZWxWeo_-NCTwsiFPFWqnMOvolVl8I6ukcq8WhUzRTD-w"
//   );

//   messaging
//     .requestPermission()
//     .then(() => {
//       console.log("Notification permission granted");
//       messaging
//         .getToken()
//         .then((token) => {
//           console.log(token, "messaging token");
//         })
//         .catch((err) => {
//           console.log("An error occured while retrieving token. ", err);
//           console.log("Error retrieving Instance ID token. ", err);
//         });
//     })
//     .catch((error) => {
//       console.log("Unable to get permission to notify. ", error);
//     });

//   messaging.onMessage((payload) => {
//     var obj = JSON.parse(payload.data.notification);
//     var notification = new Notification(obj.title, {
//       icon: obj.icon,
//       body: obj.body,
//     });
//   });
//   return;
// };

// export default initializeFirebase;

// import firebase from "firebase";

// export const initializeFirebase = () => {
//   firebase.initializeApp({
//     messagingSenderId: "147972243633",
//     projectId: "pathfinder-health",
//     apiKey: "AIzaSyA_CWrC7KgMDpp54pbnl2pEpPdY1R9cAn8",
//     appId: "1:147972243633:web:a16993533ecab79370a290",
//   });
// };

// export const askForPermissioToReceiveNotifications = async () => {
//   try {
//     const messaging = firebase.messaging();
//     await messaging.requestPermission();
//     const token = await messaging.getToken();
//     console.log('user token:', token);
//     localStorage.setItem("notificationToken", token);
//     return token;
//   } catch (error) {
//     console.error(error);
//   }
// }
