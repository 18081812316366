import { action } from "easy-peasy";

const LoaderStore = {
  isLoader: false,
  isDevMode: true,
  isAHSPP: true,

  setShowLoader: action((state) => {
    state.isLoader = true;
  }),
  setHideLoader: action((state) => {
    state.isLoader = false;
  }),
  toggleLoader: action((state) => {
    state.isLoader = !state.isLoader;
  }),
  setAHSPP: action((state, input) => {
    state.isAHSPP = input;
  }),
};

export default LoaderStore;
