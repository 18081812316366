import { action } from "easy-peasy";

const HeaderStore = {
  setHeaderActionLeft: action((state, item) => {
    state.headerActionLeft = item;
  }),

  setHeaderName: action((state, headerName) => {
    state.headerName = headerName;
  }),

  setHeaderWhite: action((state, headerWhite) => {
    state.headerWhite = headerWhite;
  }),

  setHeaderActionRight: action((state, item) => {
    state.headerActionRight = item;
  }),

  setHeader: action((state, header) => {
    state.header = header;
  }),

  setShowNav: action((state, isShowNav) => {
    state.isShowNav = isShowNav;
  }),

  setNotificationsCount: action((state, notifications) => {
    state.notificationCount = notifications;
  }),
};

export default HeaderStore;
