import { action, thunk } from "easy-peasy";

const SwycStore = {
  swycSequence: [],
  isPublicSwyc: false,
  setSywcSequence: action((state, swycSequence) => {
    state.swycSequence = swycSequence;
  }),
  updateSywcSequence: thunk((actions, swycSequence) => {
    actions.setSywcSequence(swycSequence);
  }),
  setPublicSwyc: action((state, isPublicSwyc) => {
    state.isPublicSwyc = isPublicSwyc;
  }),
  updatePublicSwyc: thunk((actions, isPublicSwyc) => {
    actions.setPublicSwyc(isPublicSwyc);
  }),
};

export default SwycStore;
