import { persist } from "easy-peasy";
import AuthStore from "store/auth";
import ChildrenStore from "store/children";
import HeaderStore from "store/header";
import LoaderStore from "store/loader";
import SwycStore from "store/swyc";

const Store = {
  ...AuthStore,
  ...ChildrenStore,
  ...LoaderStore,
  ...HeaderStore,
  swyc: persist(SwycStore, { storage: "localStorage" }),
};

export default Store;
