import { thunk, action } from "easy-peasy";
import { mutate, fetchQuery } from "utils/relay";

const ChildrenStore = {
  children: [],

  setChildren: action((state, children) => {
    state.children = children;
  }),

  pushChild: action((state, child) => {
    state.children.push(child);
  }),

  syncChild: action((state, child) => {
    let childIdx = state.children.findIndex((item) => item.id === child.id);
    state.children[childIdx] = child;
  }),

  removeChild: action((state, child) => {
    let childIdx = state.children.findIndex((item) => item.id === child.id);
    state.children.splice(childIdx, 1);
  }),

  addChild: thunk((actions, { mutation, input, error, success }) => {
    mutate({
      mutation,
      input: input,
      onSuccess: function (data) {
        actions.pushChild(data.createChild.child);
        success(data);
      },
      onFailure: function (messages) {
        error(messages);
      },
    });
  }),

  updateChild: thunk((actions, { mutation, input, error, success }) => {
    mutate({
      mutation,
      input: input,
      onSuccess: function (data) {
        actions.syncChild(data.updateChild.child);
        success(data);
      },
      onFailure: function (messages) {
        error(messages);
      },
    });
  }),

  deleteChild: thunk((actions, { mutation, input, error, success }) => {
    mutate({
      mutation,
      input: input,
      onSuccess: function (data) {
        actions.removeChild(data.deleteChild.child);
        success(data);
      },
      onFailure: function (messages) {
        error(messages);
      },
    });
  }),

  fetchChildren: thunk((actions, query) => {
    fetchQuery(query).then((data) => {
      if (data.children) {
        actions.setChildren(data.children);
      }
    });
  }),
};

export default ChildrenStore;
