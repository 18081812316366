import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "index.scss";
import * as serviceWorker from "serviceWorker";
import Loader from "components/Loader";
import { createStore, StoreProvider } from "easy-peasy";
import Store from "store";
import { initializeFirebase } from "push-notification";
import * as Sentry from "@sentry/react";
import firebase from "firebase";

const store = createStore(Store);
const {
  REACT_APP_VERSION,
  REACT_APP_HEAP_ID,
  REACT_APP_SENTRY_DSN,
} = process.env;
const LazyApp = lazy(() => import("App"));
console.log("App version :", REACT_APP_VERSION);
console.log("HEAP ID :", REACT_APP_HEAP_ID);

// Sentry.init({
//   dsn: REACT_APP_SENTRY_DSN,
//   integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

const App = () => {
  return (
    <StoreProvider store={store}>
      <Suspense fallback={<Loader />}>
        <LazyApp />
      </Suspense>
    </StoreProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
if (firebase.messaging.isSupported()) {
  initializeFirebase();
}

navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
