import React from "react";
import LogoImg from "images/img/pathfinderLogoFull.svg";
import LoaderGif from "images/new/BabySkating.gif";

const Loader = () => {
  return (
    <article className="splash-screen-new">
      <img src={LoaderGif} alt=" " className="loader-gif" />
      <div className="d-flex brand-logo brand-logo--position align-items-center">
        <div>
          <img src={LogoImg} alt=" " />
        </div>
      </div>
    </article>
  );
};

export default Loader;
