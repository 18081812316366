import { thunk, action } from "easy-peasy";
import graphql from "babel-plugin-relay/macro";

import {
  AUTH_TOKEN,
  TOKEN_TTL,
  IDLE_TIMEOUT_MIN,
  SESSION_TIMEOUT,
} from "utils/constants";
import { mutate } from "utils/relay";

var timeout = null;

const mutation = graphql`
  mutation authRefreshMutation($input: RefreshInput!) {
    refreshToken(input: $input) {
      token
      payload
    }
  }
`;

const AuthStore = {
  currentUser: null,
  authToken: window.localStorage.getItem(AUTH_TOKEN),
  isShowVerifyPopup: true,
  isShowSessionLogoutPopup: false,
  isShowPINPopup: false,

  toggleShowVerifyPopup: action((state) => {
    state.isShowVerifyPopup = !state.isShowVerifyPopup;
  }),

  setShowVerifyPopup: action((state, input) => {
    state.isShowVerifyPopup = input;
  }),

  toggleShowSessionLogoutPopup: action((state) => {
    state.isShowSessionLogoutPopup = !state.isShowSessionLogoutPopup;
  }),

  setShowSessionLogoutPopup: action((state, input) => {
    state.isShowSessionLogoutPopup = input;
  }),

  setShowPINPopup: action((state, input) => {
    state.isShowPINPopup = input;
  }),

  setAuthToken: action((state, authToken) => {
    state.authToken = authToken;
    if (authToken !== null) {
    }
  }),

  setCurrentUser: action((state, currentUser) => {
    state.currentUser = currentUser;
  }),

  setFCMToken: action((state, fcmToken) => {
    state.FCMToken = fcmToken;
  }),

  setPhysicianInvitationId: action((state, id) => {
    state.PhysicianInvitationId = id;
  }),

  setSelectedResponse: action((state, response) => {
    state.selectedResponse = response;
  }),

  clincDetails: JSON.parse(window.localStorage.getItem("clinicDetails")),

  setClincDetails: action((state, clincDetails) => {state.clincDetails = clincDetails}),

  searchText: "",

  setSearchText: action((state, searchText) => {state.searchText = searchText}),

  updateClinicDetails: thunk((actions, clincDetails) => {
    let details = JSON.stringify(clincDetails)
    window.localStorage.setItem("clinicDetails", details);
    actions.setClincDetails(clincDetails);
  }),

  setQuestionId: action((state, questionId) => {
    state.questionId = questionId;
  }),

  updateAuthToken: thunk((actions, authToken) => {
    window.localStorage.setItem(AUTH_TOKEN, authToken);
    actions.setAuthToken(authToken);
  }),

  clearAuthToken: thunk((actions) => {
    // window.localStorage.clear();
    window.localStorage.removeItem("authToken");
    actions.setAuthToken(null);
    actions.setCurrentUser(null);
    actions.setShowSessionLogoutPopup(false);
    window.localStorage.removeItem("sessionTimeout");
    window.localStorage.removeItem("clinicDetails");
    actions.updateClinicDetails(null);
  }),

  setCurrentUserData: action((state, currentUser) => {
    state.currentUser = currentUser;
  }),

  setFetchData: action((state, input) => {
    state.fetchData = input;
  }),

  editProfile: thunk((actions, { mutation, profile, error, success }) => {
    mutate({
      mutation,
      input: profile,
      onSuccess: function (data) {
        data.updateProfile
          ? actions.setCurrentUserData(data.updateProfile.user)
          : data.dayCareUpdateProfile
          ? actions.setCurrentUserData(data.dayCareUpdateProfile.user)
          : actions.setCurrentUserData(
              data.dayCareInviteUserUpdateProfile.user
            );
        success(data);
      },
      onFailure: function (messages) {
        error(messages);
      },
    });
  }),

  initRefreshToken: thunk((actions) => {
    setInterval(() => {
      if (window.localStorage.getItem(AUTH_TOKEN)) {
        mutate({
          mutation,
          input: { token: window.localStorage.getItem(AUTH_TOKEN) },
          onSuccess: function (data) {
            actions.updateAuthToken(data.refreshToken.token);
          },
          onFailure: function (messages) {
            actions.clearAuthToken();
          },
        });
      }
    }, TOKEN_TTL);
  }),

  startIdleTimer: thunk((actions, payload, { getState, getStoreState }) => {
    // window.onload = actions.resetTimer;
    // window.onmousemove = actions.resetTimer;
    // window.onmousedown = actions.resetTimer;
    // window.ontouchstart = actions.resetTimer;
    // window.onclick = actions.resetTimer;
    // window.onkeypress = actions.resetTimer;
    // window.addEventListener("scroll", actions.resetTimer, true);
    const session = window.localStorage.getItem(SESSION_TIMEOUT);
    if (!session || session === "false") {
      actions.resetTimer();
    } else if (session === "true") {
      actions.setShowSessionLogoutPopup(true);
    }
  }),

  endTimer: thunk((actions, payload, { getState, getStoreState }) => {
    let state = getState();
    if (state.authToken) {
      actions.setShowSessionLogoutPopup(true);
      window.localStorage.setItem(SESSION_TIMEOUT, true);
    }
  }),

  resetTimer: thunk((actions) => {
    clearTimeout(timeout);
    timeout = setTimeout(actions.endTimer, IDLE_TIMEOUT_MIN);
    actions.setShowSessionLogoutPopup(false);
    window.localStorage.setItem(SESSION_TIMEOUT, false);
  }),

  stopTimer: thunk((actions) => {
    clearTimeout(timeout);
    actions.setShowSessionLogoutPopup(false);
    window.localStorage.removeItem(SESSION_TIMEOUT);
  }),
};

export default AuthStore;
